/* color vars */
/* basic */
/* gray */
/* other color */
/* row line */
/* column line */
/* layout */
.layout-page {
  min-height: calc(100% - 140px);
}
.layout-page .page-header {
  padding: 15px 0;
}
.layout-page .page-header .ant-breadcrumb-link {
  padding: 15px 0;
  font-size: 22px;
}
.layout-page .page-main {
  box-sizing: border-box;
  margin-bottom: 20px;
}
.layout-page .page-main.no__space {
  padding: 0;
}
