.bd_t_1,
.bd_r_1,
.bd_b_1,
.bd_l_1 {
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    transform-origin: 0 0;
  }
}

.bd_t_1 {
  &::before {
    border-top: 1px solid @color-row-line;
    left: 0;
    top: 0;
    width: 100%;
    transform-origin: 0 top;
  }
}

.bd_r_1 {
  &::after {
    border-right: 1px solid @color-row-line;
    top: 0;
    right: 0;
    height: 100%;
    transform-origin: right 0;
  }
}

.bd_b_1 {
  &::after {
    border-bottom: 1px solid @color-row-line;
    left: 0;
    bottom: 0;
    width: 100%;
    transform-origin: 0 bottom;
  }
}

.bd_l_1 {
  &::before {
    border-left: 1px solid @color-row-line;
    top: 0;
    left: 0;
    height: 100%;
    transform-origin: left 0;
  }
}

@media (min-resolution: 2dppx) {
  .bd_t_1 {
    &::before {
      width: 200%;
      transform: scale(0.5) translateZ(0);
    }
  }

  .bd_r_1 {
    &::after {
      height: 200%;
      transform: scale(0.5) translateZ(0);
    }
  }

  .bd_b_1 {
    &::after {
      width: 200%;
      transform: scale(0.5) translateZ(0);
    }
  }

  .bd_l_1 {
    &::before {
      height: 200%;
      transform: scale(0.5) translateZ(0);
    }
  }
}

@media (min-resolution: 3dppx) {
  .bd_t_1 {
    &::before {
      width: 300%;
      transform: scale(0.333) translateZ(0);
    }
  }

  .bd_r_1 {
    &::after {
      height: 300%;
      transform: scale(0.333) translateZ(0);
    }
  }

  .bd_b_1 {
    &::after {
      width: 300%;
      transform: scale(0.333) translateZ(0);
    }
  }

  .bd_l_1 {
    &::before {
      height: 300%;
      transform: scale(0.333) translateZ(0);
    }
  }
}

// @function setSpace(@i, @is) {
//   @if (@is == 1) {
//     @return @i * @space-base;
//   } @else {
//     @return #{@i * @space-base}@unit-base;
//   }
// }

// @for @i from 1 through 5 {
//   @each @name, @value in (ml, left), (mt, top), (mr, right), (mb, bottom) {
//     .#{@name}_#{setSpace(@i, 1)} {
//       margin-#{@value}: setSpace(@i, 0);
//     }
//   }

//   @each @name, @value in (pl, left), (pt, top), (pr, right), (pb, bottom) {
//     .#{@name}_#{setSpace(@i, 1)} {
//       padding-#{@value}: setSpace(@i, 0);
//     }
//   }

//   .m_lr_#{setSpace(@i, 1)} {
//     margin-left: setSpace(@i, 0);
//     margin-right: setSpace(@i, 0);
//   }

//   .p_lr_#{setSpace(@i, 1)} {
//     padding-left: setSpace(@i, 0);
//     padding-right: setSpace(@i, 0);
//   }

//   .p_tb_#{setSpace(@i, 1)} {
//     padding-top: setSpace(@i, 0);
//     padding-bottom: setSpace(@i, 0);
//   }
// }

// @each @name, @value in (l, left), (c, center), (r, right) {
//   .text_#{@name} {
//     text-align: @value;
//   }
// }

.mt_5 {
  margin-top: 5px;
}

.mr_5 {
  margin-right: 5px;
}

.mb_5 {
  margin-bottom: 5px;
}

.ml_5 {
  margin-left: 5px;
}

.mt_10 {
  margin-top: 10px;
}

.mr_10 {
  margin-right: 10px;
}

.mb_10 {
  margin-bottom: 10px;
}

.ml_10 {
  margin-left: 10px;
}

.mt_15 {
  margin-top: 15px;
}

.mr_15 {
  margin-right: 15px;
}

.mb_15 {
  margin-bottom: 15px;
}

.ml_15 {
  margin-left: 15px;
}

.mt_20 {
  margin-top: 20px;
}

.mr_20 {
  margin-right: 20px;
}

.mb_20 {
  margin-bottom: 20px;
}

.ml_20 {
  margin-left: 20px;
}

.mt_25 {
  margin-top: 25px;
}

.mr_25 {
  margin-right: 25px;
}

.mb_25 {
  margin-bottom: 25px;
}

.ml_25 {
  margin-left: 25px;
}

.pt_5 {
  padding-top: 5px;
}

.pr_5 {
  padding-right: 5px;
}

.pb_5 {
  padding-bottom: 5px;
}

.pl_5 {
  padding-left: 5px;
}

.pt_10 {
  padding-top: 10px;
}

.pr_10 {
  padding-right: 10px;
}

.pb_10 {
  padding-bottom: 10px;
}

.pl_10 {
  padding-left: 10px;
}

.pt_15 {
  padding-top: 15px;
}

.pr_15 {
  padding-right: 15px;
}

.pb_15 {
  padding-bottom: 15px;
}

.pl_15 {
  padding-left: 15px;
}

.fl_left {
  float: left;
}

.fl_right {
  float: right;
}

.text_l {
  text-align: left;
}

.text_c {
  text-align: center;
}

.text_r {
  text-align: right;
}

.f14 {
  font-size: 14px;
}

.f16 {
  font-size: 16px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.relative {
  position: relative;
}

.hidden {
  overflow: hidden;
}

.c_black {
  color: @color-black;
}

.c_white {
  color: @color-white;
}

.c_blue {
  color: @color-default;
}

.c_red {
  color: @color-red;
}

.c_yellow {
  color: @color-yellow;
}

.c_green {
  color: @color-green;
}

.c_gray {
  color: @color-light-grey;
}

.c_yellow {
  color: @color-yellow;
}

.bg_gray {
  background: @color-background;
}

.bg_white {
  background: @color-white;
}

.bg_blue {
  background: @color-default;
}