/* color vars */
/* basic */
/* gray */
/* other color */
/* row line */
/* column line */
/* layout */
.layout-sider {
  position: relative;
  background-color: #2b3243;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  flex: 0 0 225px;
  transition: all 0.3s;
}
.layout-sider .sider-logo {
  display: block;
  padding: 10px 24px 0;
  max-height: 63px;
  overflow: hidden;
}
.layout-sider .sider-logo .logo-img {
  width: 100%;
}
.layout-sider .sider-menus {
  margin-top: 6px;
}
.layout-sider .sider-menus-item {
  cursor: pointer;
  padding: 14px 30px;
  color: #ccc;
  border-bottom: 1px solid #272727;
}
.layout-sider .sider-menus-item.active {
  color: #fff;
  background-color: #447add;
}
.layout-sider .sider-menus-item.line {
  position: relative;
}
.layout-sider .sider-menus-item.line::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 35%;
  border-top: 1px solid #999;
}
.layout-sider .sider-menus .menus-item__icon {
  float: left;
  margin-top: 6px;
  margin-right: 10px;
  display: none;
}
.layout-sider .sider-menus .menus-item__text {
  float: left;
  display: block;
  font-size: 17px;
}
.layout-sider .sider-menus .menus-item__arrow {
  font-size: 15px;
  float: right;
  margin-top: 8px;
}
.layout-sider.not_fold {
  flex: 0 0 55px;
}
.layout-sider.not_fold .sider-menus-item {
  padding: 17px 0;
  border-bottom: 1px solid #504f4f;
}
.layout-sider.not_fold .sider-menus-item:first-child {
  border-top: 1px solid #504f4f;
}
.layout-sider.not_fold .sider-menus-item .menus-item {
  width: 55px;
}
.layout-sider.not_fold .sider-menus-item .menus-item__icon {
  float: none;
  display: block;
  width: 20px;
  margin: 0 auto;
}
.layout-sider.not_fold .sider-menus-item .menus-item__text,
.layout-sider.not_fold .sider-menus-item .menus-item__arrow {
  display: none;
}
.layout-sider.not_fold .sider-logo {
  padding: 12px;
}
.layout-sider .sider-icons {
  position: absolute;
  left: 15px;
  bottom: 20px;
}
.layout-sider .sider-icons-item {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
