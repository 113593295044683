/* color vars */
/* basic */
/* gray */
/* other color */
/* row line */
/* column line */
/* layout */
.layout-head {
  background-color: #fff;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 2px 8px #f0f1f2;
  position: relative;
  z-index: 10;
}
.layout-head .menu-item {
  float: left;
  margin-left: 20px;
  cursor: pointer;
}
.layout-head .menu-item .anticon {
  font-size: 19px;
}
.layout-head .menu-item:hover .anticon {
  color: #447add;
  border-color: #447add;
}
.layout-head .user-wrap .ant-avatar {
  margin-top: -6px;
}
.layout-head .user-wrap .ant-avatar .anticon-user {
  margin-top: 3px;
}
.layout-head .user-wrap .user-name {
  margin-left: 5px;
}
.layout-head .user-wrap:hover .user-name {
  color: #447add;
}
