html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input,
textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  vertical-align: baseline;
}

body {
  background-color: @color-background;
}

li {
  list-style: none;
}

.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.layout-wrap {
  display: flex;
  height: 100%;
  overflow-y: hidden;
}

.layout-container {
  flex: 1;
  overflow-y: auto;
  height: 100%;
}

.ant-table-thead > tr > th {
  background-color: @color-white;
  font-size: 17px;
}
// 修复按钮字体大小
.ant-btn-round.ant-btn-sm {
  font-size: @font-size-sm;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: @color-white;
}

::-webkit-scrollbar-thumb {
  border-radius: 35px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: @color-light-grey-s;
}

.top-btn__wrap{
  position: absolute;
  right: 0;
  top: 0;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.form-filter{
  &__wrap{
    padding: 15px;
    background:  @color-white;
    margin-bottom: 15px;
    .ant-form-item{
      margin-bottom: 15px;
    }
  }
  &__main{
    margin-bottom: -15px;
  }
}

.flex{
  display: flex;
  &.is-between{
    justify-content: space-between;
  }

  &__item{
    &.is-right{
      margin-left: auto;
    }
  }
}